var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-toolbar',{attrs:{"dark":"","color":"accent"}},[_c('v-toolbar-title',[_c('div',{directives:[{name:"t",rawName:"v-t",value:('user.manage_users'),expression:"'user.manage_users'"}]})]),_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('user.create_user'),expression:"'user.create_user'"}],staticClass:"font-weight-bold",attrs:{"color":"secondary","to":"/main/admin/users/create"}})],1),_c('v-card',{staticClass:"ma-3"},[_c('v-card-title',[_c('v-text-field',{staticClass:"mx-4",attrs:{"color":"secondary","append-icon":"search","label":"Pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.item.email))]),_c('td',[_vm._v(_vm._s(props.item.full_name))]),_c('td',[(props.item.is_active)?_c('v-icon',[_vm._v("checkmark")]):_vm._e()],1),_c('td',_vm._l((props.item.roles
              .filter((r) => !['ADMIN', 'USER_MANAGER', 'OPERATOR', 'EXPORT'].includes(r.name))
              .sort((a, b) => {
                let nameA = a.name.toUpperCase();
                let nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              })),function(role,index){return _c('v-chip',{key:index,attrs:{"dark":"","label":"","small":"","color":role.name == 'T2' ? `lightblue` : role.name == 'T1' ? `green` : `orange`}},[_vm._v(" "+_vm._s(role.name)+" ")])}),1),_c('td',{staticClass:"justify-center layout px-0"},[_c('v-tooltip',{attrs:{"top":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('user.edit')))]),_c('v-btn',{attrs:{"slot":"activator","flat":"","to":{ name: 'main-admin-users-edit', params: { id: props.item.id } }},slot:"activator"},[_c('v-icon',[_vm._v("edit")])],1)],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }