
import { store } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import { readEquipments } from '@/store/equipments/getters';
import { dispatchGetEquipments, dispatchSwitchMaintenanceState, dispatchSwitchActiveState } from '@/store/equipments/actions';
import { readUserRoles } from '@/store/main/getters';

const routeGuardMain = async (to, from, next) => {
  if (!['OPERATOR', 'ADMIN'].some((r) => readUserRoles(store)?.includes(r))) {
    next('/main');
  } else {
    next();
  }
};

@Component
export default class Equipments extends Vue {
  public headers = [
    {
      text: this.$root.$i18n.t('equip.equip_name'),
      sortable: true,
      value: 'type',
      align: 'left',
    },
    {
      text: this.$root.$i18n.t('equip.equip_station'),
      sortable: true,
      value: 'station.location',
      align: 'left',
    },
    {
      text: 'Terminal',
      sortable: true,
      value: 'station.terminal',
      align: 'left',
    },
    {
      text: 'Em manutenção',
      sortable: true,
      value: 'maintenance',
      align: 'left',
    },
    {
      text: 'Em operação',
      sortable: true,
      value: 'active',
      align: 'left',
    },
  ];
  get equipments() {
    return readEquipments(this.$store);
  }

  public async changeMaintenanceState(id) {
    await dispatchSwitchMaintenanceState(this.$store, id);
  }

  public async changeActiveState(id) {
    await dispatchSwitchActiveState(this.$store, id);
  }

  public async mounted() {
    await dispatchGetEquipments(this.$store);
  }

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }
}
